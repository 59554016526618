@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1584308666744-24d5c474f2ae?q=80&w=3830&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80) fixed center center;
}

.order-font {
  font-size: 18rem;
}


.usecase {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)), url(https://images.ctfassets.net/4f3rgqwzdznj/4Anf2TKZz24ovWwCRzd21x/0e46613cfbf3009a7bd89df159cd1ee2/senior_man_looking_down_at_pills_thinking_842975468.jpg) fixed center center;
}